.field-container-2 {
  border-radius: 9px;
  /* width: 50%; */
  border: 2px solid #f1f1f2;
  padding: 2px 14px 16px 12px;
  margin-bottom: 20px;
  background-color: #ffffff;
}

.fields {
  display: inline;
  width: 100%;
}

.grabber {
  display: inline;
  width: 20px;
  margin-left: 20px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: grab;
}

.selection-option {
  display: flex;
  margin-bottom: 11px;
}

.selection-option-dragger {
  width: 12px;
  margin-top: auto;
  margin-bottom: auto;
  flex: 0.1;
  max-height: 16px;
}

.selection-option-delete {
  cursor: pointer;
  float: right;
  width: 23px;
  margin-bottom: 7px;
  margin-top: auto;
  margin-bottom: auto;
  flex: 0.1;
  max-height: 16px;
}

.selection-option-data {
  flex: 0.8;
}

.editor-button {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
}

.divider {
  border-left: 1px solid #dadce0;
  height: 24px;
  margin: 0 14px 2px 2px;
  width: 0;
  float: right;
  margin-top: auto;
  margin-bottom: auto;
}

.multiButtonSectionFields {
  display: flex;
  font-size: 15px;
  font-family: Avenir Next;
  float: right;
}

.field-footer {
  display: flex;
  border-top: 1px solid #eaeaea;
  padding-top: 9px;
  margin-left: 10px;
}

.editor-icon {
  height: 18px;
}

.header-icon {
  height: 13px;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.locked-icon {
  height: 17px;
  margin-left: 8px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.header-text {
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 600;
  color: #232a58;
  font-size: 14px;
}

.header-fields {
  display: flex;
  height: 46px;
  padding: 0px 10px;
  margin-bottom: 2px;
  margin-top: 3px;
}

.editor-footer {
  margin-top: 10px;
  // border-top: 1px solid #dcdcdc;
  padding-top: 15px;
}

.publish-button {
  float: right;
  padding: 9px 15px;
  background-color: #01845a;

  font-weight: 500;
  color: #ffffff;
  border-radius: 6px;
  cursor: pointer;
}

.disabled {
  cursor: disabled;
  background-color: #d8d8d8;
  color: #5f5f5f;
}

.add-option-button {
  padding: 8px 9px 17px 10px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  color: #586adb;
  cursor: pointer;
}

.add-option-icon {
  height: 11px;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.add-field-button {
  float: left;
  /* float: right; */
  padding: 9px 15px;
  background-color: #6525ff;
  font-weight: 500;
  color: #ffffff;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
}

.add-button-icon {
  height: 18px;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.add-type-icon {
  height: 18px;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.type-button {
  padding: 10px;
  background-color: #ffffff;
  margin-bottom: 6px;
  display: flex;
  cursor: pointer;
  font-weight: 500;
}

.type-button:hover {
  background-color: #ececec;
}

.new {
  border: 2px solid #b6caff;
}

.back-image {
  height: 16px;
  margin-bottom: auto;
  margin-top: auto;
  margin-right: 8px;
}

.back-title {
  cursor: pointer;
  color: #5c56ff;
  font-size: 19px;
  /* margin-top: auto; */
  display: flex;
  font-weight: 600;
  margin-top: 5px;
}

.disabled {
  cursor: not-allowed;
  background-color: #d8d8d8;
  color: #000000;
}

.info {
  margin-top: 20px;
  padding: 14px 19px 3px 15px;
  background-color: #fff0d5;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  border-radius: 7px;
}

.info-icon {
  height: 25px;
  margin-right: 11px;
  margin-top: 3px;
}

.info-text {}

.error {
  border: 2px solid #ff5858;
}

.subfield-container {
  background-color: #e7e7e7;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.subfield-delete-icon {
  height: 20px;
  cursor: pointer;
  float: right;
}

.subfield-delete-icon:hover {
  color: #000000;
}

.add-subfield {
  display: flex;
  align-items: center;
}