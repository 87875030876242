.newCardTitle {
  color: #2b2b2b;
  font-size: 22px;
  font-weight: 500;
  font-family: "Avenir Next";
  margin-bottom: 20px;
}

.multiButtonSection {
  font-size: 15px;
  font-family: Avenir Next;
  float: right;
  margin-top: 20px;
}

.createButton {
  display: inline-block;
  padding: 7px 12px;
  border-radius: 4px;
  background-color: #1663ff;
  color: #ffffff;
  cursor: pointer;
}

.cancelButton {
  display: inline-block;
  margin-right: 12px;
  cursor: pointer;
  color: #4b4b4b;
}

.userImageContainer {
  background-color: #ffffff;
  display: inline-block;
  vertical-align: middle;
}

.userImage {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.initialsAvatar {
  color: #f56a00;
  background-color: #fde3cf;
  vertical-align: middle;
  font-size: 15px;
}

.userNameContainer {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
}

.userName {
  font-family: Avenir Next;
  font-weight: 500;
  color: #000000;
  font-size: 16px;
  margin-bottom: 6px;
  font-weight: 600;
  text-decoration: none;
}

.actionButton {
  color: rgb(26, 115, 232);
  border: 0.7px solid rgb(227, 225, 225);
  font-family: "Avenir Next";
  font-weight: 500;
  font-size: 12px;
  background-color: rgb(255, 255, 255);
  padding: 8px 15px;
  border-radius: 6px;
  cursor: pointer;
}

.preview-button {
  font-size: 15px;
  font-weight: 500;
  font-family: Avenir Next;
  color: #397bfe;
}

.cardlist-header {
  margin-bottom: 20px;
}

.no-margin {
  margin-bottom: 0px;
}




/*13 inch or small laptop*/
@media only screen and (min-width: 768px) and (max-width: 1500px) {
  .userName {
    font-family: Avenir Next;
    font-weight: 500;
    color: #000000;
    font-size: 14px;
    margin-bottom: 6px;
    font-weight: 600;
    text-decoration: none;
  }

  .multiButtonSection {
    font-size: 13px;
    font-family: Avenir Next;
    float: right;
  }

  
}