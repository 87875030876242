.tag-span {
  color: #40a9ff;
  background-color: #40a9ff10;
  border-radius: 5px;
  padding: 2px;
}

.current-user {
  background-color: rgb(255, 234, 182) !important;
}

.ant-popover {
  transform-origin: 0px 0px !important;
}

.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  visibility: hidden;
}
