@media only screen and (min-width: 1900px) {
  .growth-home {
    margin-left: 10%;
    margin-right: 10%;
  }
}

/**Desktop Query*/
@media only screen and (min-width: 768px) and (max-width: 1900px) {
  .growth-home {
    margin-left: 10%;
    margin-right: 10%;
  }

  .used-by {
    position: absolute;
    margin-top: -74px;
    text-align: center;
    background-color: #ff9041;
    margin-bottom: 37px;
    color: #ffffff;
    font-size: 16px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    padding-top: 6px;
    margin-left: 58px;
    padding-bottom: 7px;
    width: 205px;
  }
}

/*Tablet Query*/
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .growth-home {
    margin-left: 1%;
    margin-right: 1%;
  }
}

/*Mobile Query*/
@media only screen and (max-width: 480px) {
  .growth-home {
    margin-left: 0px;
    margin-right: 0px;
  }

  .used-by {
    position: absolute;
    margin-top: -74px;
    text-align: center;
    background-color: #ff9041;
    margin-bottom: 37px;
    color: #ffffff;
    font-size: 16px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    padding-top: 6px;
    margin-left: 21px;
    padding-bottom: 7px;
    width: 205px;
  }
}

.growth-header {
  text-align: center;
  font-size: 30px;
  color: #575757;
  font-family: Avenir Next;
  font-weight: 500;
  margin-top: 40px;
}

.growth-desc {
  text-align: center;
  font-size: 20px;
  color: #575757;
  font-family: Avenir Next;
  font-weight: 400;
  margin-top: 20px;
}

.offer-card {
  background: #ffffff;
    box-shadow: 0px 1px 5px rgba(119, 119, 119, 0.277853);
    border-radius: 8px;
    width: 400px;
    padding: 37px;
    text-align: center;
    display: inline-block;
    margin-right: 50px;
    margin-bottom: 37px;
    margin-top: 24px;
}

.offer-summary {
  /* width: 179px; */
  /* height: 33px; */
  font-family: Avenir Next;
  /* font-style: normal; */
  /* font-weight: 600; */
  font-size: 16px;
  /* line-height: 11px; */
  /* letter-spacing: 0.634921px; */
  color: #575757;
  margin-top: 12px;
}

.offers-section {
  padding: 20px;
  margin-top: 20px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.offer-details {
  white-space: normal;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  /* line-height: 11px; */
  /* letter-spacing: 0.714286px; */
  color: #000000;
  margin-top: 15px;
  padding: 6px;
  text-align: left;
}

.offer-title {
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  /* line-height: 11px; */
  text-align: center;
  letter-spacing: 0.634921px;
  color: #575757;
  margin-top: 14px;
}

.offer-image {
  height: 22px;
}

.perks-title {
  height: 11px;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  /* line-height: 16px; */
  /* text-align: center; */
  letter-spacing: 0.634921px;
  color: #1c82f5;
  margin-top: 20px;
  margin-bottom: 29px;
}

.perk-description {
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* line-height: 11px; */
  /* letter-spacing: 0.740741px; */
  color: #000000;
  margin-left: 10px;
  text-align: left;
  display: inline;
}

.desc-check {
  height: 25px;
  min-height: 25px;
  margin-top: 9px;
  display: inline;
}


.perk-check {
  height: 16px;
  min-height: 16px;
  display: inline;
  margin-top: 3px;
}

.perk {
  margin-bottom: 9px;
  display: flex;
}

.perk-section {
  display: inline-block;
}

.redeem-button {
  border-radius: 8px;
  background-color: #2d89f6;
  color: #ffffff;
  padding: 9px;
  width: 50%;
  /* text-align: center; */
  cursor: pointer;
  margin-top: 20px;
  display: inline-block;
}

.redeemed {
  background-color: #e6e6e6;
  color: #000000;
  cursor: not-allowed;
}

