.table-attachments {
    display: flex;
}

.view-files {
    margin-right: 20px;
    cursor: pointer;
    /* background-color: #6d3f3f; */
    color: #1c68ff;
    /* vertical-align: middle; */
    /* justify-content: center; */
    /* vertical-align: middle; */
    /* margin: auto; */
    padding: auto;
    /* padding: 5px; */
    font-size: 14px;
    font-family: Avenir Next;
}

.download-progress {
  //  position: absolute;
}