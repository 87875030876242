.checkbox {
    display: block;
    margin-left: 0px;
    padding: 5px;
}

.checkbox-container {
    margin-top: 15px;
    margin-bottom: 15px;
}

.input {
    display: block;
    width: 80%;
}

.ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-left: 0px;
}

.input-label-title {
    font-size: 17px;
    font-weight: 500;
    font-family: "Avenir Next";
    margin-bottom: 7px;
}

.project-settings-section-card {
    display: inline-block;
    border: 2px solid rgb(238, 238, 238);
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    margin-top: 20px;
}

.project-settings-section-header {
    display: flex;
    align-items: center;
    padding: 4px 10px 16px 10px;
}

.project-settings-section-icon {
    align-items: center;
    margin-right: 10px;
}

.project-settings-section-name {
    color: #2a2a2a;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    text-align: right;
    font-size: 16px;
    font-weight: 500;
}

.project-settings-section-action {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0px 4px;
    font-style: inherit;
    font-feature-settings: inherit;
    font-variant: inherit;
    font-stretch: inherit;
    font-family: inherit;
    text-decoration: none;
    transition: background-color 0.6s ease 0s, border-color 0.6s ease 0s;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    font-weight: var(--lns-fontWeight-medium);
    border-radius: var(--lns-radius-medium);
    display: inline-flex;
    height: calc(4.5 * var(--lns-unit, 8px));
    min-width: calc(4.5 * var(--lns-unit, 8px));
    font-size: var(--lns-fontSize-medium);
    line-height: var(--lns-lineHeight-medium);
    background-color: var(--lns-color-disabledBackground);
    color: var(--lns-color-disabledContent);
    border: 0px;
    border-radius: 6px;
    font-weight: 500;
    color: #5c56ff;
    /* color: #ffffff; */
    font-size: 15px;
    cursor: pointer;
}

.next-arrow {
    height: 12px;
    margin-left: 5px;
}

.create-section-button {
    padding: 10px 22px;
    background-color: #5c56ff;
    color: #ffffff;
    display: inline-block;
    margin-top: 20px;
    border-radius: 8px;
    cursor: pointer;
}