.lim-card {
    border-radius: 6px;
    border: 2px solid #ededed;
    padding: 14px;
    background-color: #ffffff;
    cursor: pointer;
    margin-bottom: 10px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
    font-size: 14px;
}

.lim-card-item-number {
    display: flex;
    font-weight: 500;
    color: #000000;
    align-items: center;
    margin-bottom: 7px;
}

.lim-card-item-primary-value {
    font-weight: 500;
    color: #0e0e0e;
    white-space: pre-wrap;
    outline: none;
    font-size: 14px;
}

.lim-card:hover {
    border: 2px solid blue;

}

.lim-remove-button {
    display: flex;
    justify-content: flex-end;
    color: gray;
    height: 20px;
}

.lim-card-content {
    width: 80%;
    flex: 1;
}

.lim-card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.lim-count-chip {
    /* border: 1px solid #307dff; */
    background-color: #a3a3a3;
    border-radius: 16px;
    /* padding-right: 10px; */
    margin-right: 5px;
    display: inline-flex;
    align-items: center;
    /* margin-bottom: 0px; */
    vertical-align: center;
    justify-content: center;
    font-weight: 500;
    font-size: 9px;
    line-height: 12px;
    color: #ffffff;
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.lim-remove-button:hover {
    color: black;
}

.lim-add-items-button {
    padding: 8px 17px;
    border: 1px solid #1d67ff;
    border-radius: 5px;
    display: inline-block;
    background-color: white;
    color: #256cff;
    font-size: 13px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-top: 10px;


}

.lim-add-button-icon {
    color: #0053ff;
    margin-right: 5px;
    height: 15px;
}

.lim-add-items-button.table {
    padding: 3px 8px;
    border: 1px solid #1d67ff;
    border-radius: 5px;
    background-color: white;
    color: #256cff;
    font-size: 13px;
    cursor: pointer;
    margin-top: 0px;
    display: flex;
    align-items: center;
    font-weight: 500;
}


.lim-add-items-button:hover .lim-add-button-icon {
    color: #000000;
}

.lim-add-items-button:hover {
    border: 1px solid #000000;
    border-radius: 5px;
    background-color: white;
    color: #000000;
}

.rm-card {
    padding: 7px;
    width: 75%;
}

.lim-search-icon {
    height: 20px;
    margin-right: 7px;
    color: #9f9f9f;
}

.lim-search-container {
    padding: 10px;
    border-radius: 6px;
    border: 2px solid #cfcfcf;
    /* background-color: #efefef; */
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.lim-search-input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    color: #000000;
    background-color: transparent;
}

.lim-card-title-icon {
    height: 15px;
    margin-right: 7px;
    color: #9f9f9f;
}

.lim-card-field-title {
    font-size: 12px;
    color: #8f8f8f;
    font-weight: 500;
}

.lim-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    margin-top: 30px;
    font-size: 14px;
    color: #000000;
}

.lim-empty-icon {
    height: 20px;
    width: 20px;
    margin-bottom: 0px;
}