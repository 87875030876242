.highlighted {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #79b0fa99;
  padding: "500px";
}

.addPaymentButton {
  /* Rectangle 3: */
  border: 2px solid #555eee;
  border-radius: 7px;
  color: #555eee;
  font-family: "Brown";
  padding: 10px;
  height: 30px;
  margin-top: 40px;
}

.expand img:hover {
  content: url("../images/chat_bubble_hover.svg");
}

.expand_attach img:hover {
  content: url("../images/circle_attach_blue.svg");
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a9a9a9;
  opacity: 0.5;
  /* Firefox */
}

ul {
  padding-left: 0px;
}

.topButton {
  color: rgb(26, 115, 232);
  border: 0.7px solid rgb(199, 199, 199);
  font-family: "Avenir Next";
  font-weight: 500;
  font-size: 15px;
  background-color: rgb(255, 255, 255);
  padding: 8px 17px;
  border-radius: 6px;
  cursor: pointer;
  float: right;
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
}

.ant-input-search>.ant-input {
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
}

.dashContainer {
  line-height: 1.5 !important;
}

.project-card-title {
  font-family: "Avenir Next";
  font-size: 20px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.project-updated-title {
  font-family: "Avenir Next";
    font-size: 13px;
    font-weight: 400;
    color: rgb(125, 125, 125);
    text-decoration: none;
    margin-top: 2px;
}

/**Desktop Query*/
@media only screen and (min-width: 768px) {
  .projectsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px 30px;
  }

  .dashContainer {
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 40px;
    margin-bottom: 200px;
  }
}

/*13 inch or small laptop*/
@media only screen and (min-width: 768px) and (max-width: 1500px) {
  .projectsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px 30px;
  }

  .topButton {
    font-size: 13px;
  }

  .dashContainer {
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 40px;
    margin-bottom: 200px;
  }

  .project-card-title {
    font-family: "Avenir Next";
    font-size: 16px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    text-decoration: none;
  }

  .project-updated-title {
    font-family: "Avenir Next";
      font-size: 13px;
      font-weight: 400;
      color: rgb(125, 125, 125);
      text-decoration: none;
      margin-top: 2px;
  }
}

/*Tablet Query*/
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .projectsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px 30px;
  }

  .dashContainer {
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 40px;
    margin-bottom: 200px;
  }
}

/*Mobile Query*/
@media only screen and (max-width: 480px) {
  .projectsGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px 30px;
  }

  .dashContainer {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 20px;
    margin-bottom: 100px;
  }
}

.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}

.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu>.react-contextmenu-item {}

.react-contextmenu-item.react-contextmenu-submenu>.react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}

.searchbar {
  border: 0;
  width: 200px;
  float: right;
  height: 40px;
  margin-right: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
  border-radius: 6px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 10;
  padding-left: 10;
}

.searchbar-input {
  border: none;
}

.searchbar-icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-right: 10px;
  color:rgba(0, 0, 0, 0.15);
}

.searchbar-input:focus {
  outline: none;
}