@import "~antd/dist/antd.css";

.searchModal .ant-modal-content {
  border-radius: 10px;
}

.ant-drawer-title {
  margin: 0;
  color: rgba(53, 53, 53, 0.85);
  font-weight: 500;
  font-size: 16px;
  font-family: Avenir Next;
  line-height: 22px;
}

.ant-drawer-content-wrapper {
    width: 640px;
    padding: 20px;
    box-shadow: none;
    border-radius: 20px;
}

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
}

.ant-drawer-content {
  border-radius: 20px;
}


.App {
  text-align: center;
  background-color: #fafafa;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.cancelButton {
  color: #0076f0;
  font-size: 15px;
}

.filledButton {
  background-color: #0071f0;
  color: rgb(255, 255, 255);
  /* border-color: rgb(117, 71, 255); */
  font-family: "Avenir Next";
  font-weight: 500;
  font-size: 13px;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 15px 8px 15px;
}

.hairlineButton {
  color: rgb(26, 115, 232);
  border: 0.7px solid rgb(227, 225, 225);
  font-family: "Avenir Next";
  font-weight: 500;
  font-size: 12px;
  background-color: rgb(255, 255, 255);
  padding: 8px 15px;
  border-radius: 6px;
  cursor: pointer;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/Avenir-Next/AvenirNext-DemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: heavy;
}

@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/Avenir-Next/AvenirNext-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: medium;
}

@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/Avenir-Next/AvenirNext-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("../fonts/Sofia Pro Black.otf") format("opentype");
  font-weight: 600;
  font-style: heavy;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("../fonts/Sofia Pro Medium.otf") format("opentype");
  font-weight: 400;
  font-style: medium;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("../fonts/Sofia Pro Semi Bold.otf") format("opentype");
  font-weight: 500;
  font-style: medium;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("../fonts/sofia-pro-regular-webfont.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("../fonts/Sofia Pro Ultra Light.otf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Brown";
  src: url("../fonts/Brown-Regular (1).otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Brown";
  src: url("../fonts/BrownPro-Light.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Brown";
  src: url("../fonts/BrownPro-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/**Desktop Query*/
@media only screen and (min-width: 768px) {
  .section {
    margin-left: 33%;
    margin-right: 20%;
  }

  .sectionMedium {
    margin-left: 30%;
    margin-right: 15%;
  }

  .sectionLarge {
    margin-left: 20%;
    margin-right: 5%;
  }

  .invoicePage {
    padding-bottom: 20px;
    margin-top: 40px;
    margin-bottom: 100px;
    margin-left: 25%;
    margin-right: 25%;
  }

  .remainingBalanceBlock {
    margin-top: 1em;
    max-width: 100%;
    margin-right: 20px;
    color: rgb(0, 0, 0);
    margin-bottom: 1em;
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
    float: right;
    text-align: right;
  }

  .ant-input-search {
    border: 0;
    width: 22%;
    float: right;
    height: 40px;
    margin-right: 20px;
    box-shadow: rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
}

/*Tablet Query*/
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .section {
    margin-left: 33%;
    margin-right: 20%;
  }

  .sectionMedium {
    margin-left: 33%;
    margin-right: 3%;
  }

  .sectionLarge {
    margin-left: 20%;
    margin-right: 5%;
  }

  .invoicePage {
    padding-bottom: 20px;
    margin-top: 40px;
    margin-bottom: 100px;
    margin-left: 10%;
    margin-right: 10%;
  }

  .remainingBalanceBlock {
    margin-top: 1em;
    max-width: 100%;
    margin-right: 20px;
    color: rgb(0, 0, 0);
    margin-bottom: 1em;
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
    float: right;
    text-align: right;
  }

  .ant-input-search {
    border: 0;
    width: 42%;
    float: right;
    height: 40px;
    margin-right: 20px;
    box-shadow: rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
}

/*Mobile Query*/
@media only screen and (max-width: 480px) {
  .section {
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 20px;
  }

  .sectionMedium {
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 20px;
  }

  .sectionLarge {
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 20px;
  }

  .invoicePage {
    padding-bottom: 20px;
    margin-top: 40px;
    margin-bottom: 100px;
    margin-left: 2%;
    margin-right: 2%;
  }

  .remainingBalanceBlock {
    max-width: 100%;
    margin-left: 20px;
    color: rgb(0, 0, 0);
    margin-bottom: 1em;
    font-weight: 500;
    /* display: inline-block; */
    vertical-align: middle;
    /* float: right; */
    /* text-align: right; */
  }

  .ant-input-search {
    border: 0;
    width: 55%;
    float: right;
    height: 40px;
    margin-right: 20px;
    box-shadow: rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
}

/* Global Styles*/

.sectionTitle {
  color: rgb(90, 118, 133);
  margin-bottom: 28px;
  width: 100%;
  font-family: Brown;
  font-size: 23px;
  font-weight: 300;
}

.bs-Fieldset-row {
  box-sizing: content-box;
  border-bottom: 1px solid #edf1f3;
  position: relative;
  padding: 0 0 0 20px;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 48px;
}

.bs-Fieldset-row .bs-Fieldset-label {
  position: relative;
  min-width: 42%;
  width: 42%;
}

.bs-Fieldset-fields {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 50px;
  flex-basis: 200px;
}

.hs-Form-row,
.hs-SelectMenuInput-option {
  min-width: 146px;
}

.bs-TextInput {
  border: none;
  color: #617079;
  font-family: Camphor, Segoe UI, Open Sans, sans-serif;
  font-weight: 500;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  display: block;
  padding: 12px 0;
  width: 100%;
  height: 48px;
  font-size: 15px;
  outline: none;
  tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.hs-ContentGroup-content {
  top: 0;
  left: 0;
  right: 0;
}

.hs-ContentGroup {
  position: relative;
  overflow: hidden;
}

.dropdownFilter {
  color: #5D636A;
  background-color: #ffffff;
  padding: 7px 11px;
  border: 1px solid #EBEBEB;
  border-radius: 9px;
}


p {
  margin-bottom: 0;
}



/*13 inch or small laptop*/
@media only screen and (min-width: 768px) and (max-width: 1500px) {
  .cancelButton {
    color: #0076f0;
    font-size: 13px;
  }
}