.searchSectionHeader {
  display: flex;
  padding-top: 7px;
  padding-bottom: 7px;
  align-items: center;
  margin-top: 10;
  font-size: 16px;
  margin-bottom: 8px;
}

.searchLink {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  text-align: right;
}

.expandButton {
  padding: 0px 10px 10px 10px;
  color: #0081ff;
  font-size: 16px;
  font-family: Avenir Next;
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
}

.ant-modal-body input {
  outline: none;
}
